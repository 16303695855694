<template>
    <!-- form -->
    <validation-observer ref="loginValidation">
        <b-form class="auth-login-form mt-2" @submit.prevent >
            <!-- Mobile -->
            <b-form-group :label="$t('Mobile')" label-for="login-email">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Mobile')"
                    rules="required"
                >
                    <b-form-input
                        id="login-email"
                        v-model="form.username"
                        :state="errors.length > 0 ? false:null"
                        name="login-email"
                        :placeholder="$t('Please Enter...')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
                <div class="d-flex justify-content-between">
                    <label for="login-password">{{$t('Password')}}</label>
                    <b-link :to="{name:'forgot'}">
                        <small>{{$t('Forgot Password')}}?</small>
                    </b-link>
                </div>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Password')"
                    rules="required"
                >
                    <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                    >
                        <b-form-input
                            id="login-password"
                            v-model="form.password"
                            :state="errors.length > 0 ? false:null"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            name="login-password"
                            :placeholder="$t('Please Enter...')"
                        />
                        <b-input-group-append is-text>
                            <feather-icon
                                class="cursor-pointer"
                                :icon="passwordToggleIcon"
                                @click="togglePasswordVisibility"
                            />
                        </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
                <b-form-checkbox
                    id="remember-me"
                    v-model="isRemember"
                    name="checkbox-1"
                >
                    {{$t('Remember Me')}}
                </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
            >
                {{$t('Sign in')}}
            </b-button>
        </b-form>
    </validation-observer>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
	BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {accountLogin} from '@/libs/apis/userApi'
import md5 from "blueimp-md5"
import {getStorage, setStorage, removeStorage} from "@/libs/utils"

export default {
	components: {
		BRow,
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BInputGroupAppend,
		BInputGroup,
		BFormCheckbox,
		BImg,
		BForm,
		BButton,
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			isRemember: true,
			required,
			email,
			form: {
				username: '',
				password: ''
			}
		}
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		}
	},
	created() {
		let account = getStorage('LOGIN_ACCOUNT')
		if(account){
			this.form = account
		}else{
			this.form = {
				username: '',
				password: ''
			}
		}
	},
	methods: {
		validationForm() {
			this.$refs.loginValidation.validate().then(success => {
				if (success) {
					this.doLogin()
				}
			})
		},
		doLogin () {
			let params = Object.assign({},this.form,{password: md5(this.form.password)})
			accountLogin(params).then(res=>{
				let {avatar,nickname,token} = res
				let userInfo = {avatar,nickname,token}
				this.$store.commit("app/SET_USER_INFO",userInfo)
				if(this.isRemember){
					setStorage('LOGIN_ACCOUNT',this.form)
				}else{
					removeStorage('LOGIN_ACCOUNT')
				}
				this.$router.replace('/admin')
			},err=>{
				this.$toast.error(err.message)
			})
		},
	},
}
</script>
