<template>
    <!-- form -->
    <validation-observer ref="loginValidation">
        <b-form class="auth-login-form mt-2" @submit.prevent >
            <!-- Mobile -->
            <b-form-group :label="$t('Mobile')" label-for="login-email">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Mobile')"
                    rules="required"
                >
                    <b-form-input
                        id="login-email"
                        v-model="form.tel"
                        :state="errors.length > 0 ? false:null"
                        name="login-email"
                        type="tel"
                        :placeholder="$t('Please Enter...')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <!-- code -->
            <b-form-group :label="$t('Code')" label-for="register-code">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Code')"
                    rules="required"
                >
                    <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                    >
                        <b-form-input
                            id="register-code"
                            v-model="form.code"
                            class="form-control-merge"
                            type="tel"
                            :state="errors.length > 0 ? false:null"
                            name="register-code"
                            :placeholder="$t('Please Enter...')"
                        />
                        <b-input-group-append is-text>
                            <a href="javascript:;"
                                class="text-primary" v-if="codeSeconds==0"
                                @click="getCode(form.tel)"
                            >{{$t('Get Verification Code')}}</a>
                            <span class="text-grey" v-else>{{codeSeconds}}s</span>
                        </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
                <b-form-checkbox
                    id="remember-me"
                    v-model="isRemember"
                    name="checkbox-1"
                >
                    {{$t('Remember Me')}}
                </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
            >
                {{$t('Sign in')}}
            </b-button>
        </b-form>
    </validation-observer>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
	BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { sendCaptcha } from '@/mixins/captcha'
import { mobileLogin } from '@/libs/apis/userApi'
import md5 from "blueimp-md5"
import {getStorage, setStorage, removeStorage} from "@/libs/utils"

export default {
	components: {
		BRow,
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BInputGroupAppend,
		BInputGroup,
		BFormCheckbox,
		BImg,
		BForm,
		BButton,
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [sendCaptcha],
	data() {
		return {
			isRemember: true,
			required,
			email,
			form: {
				tel: '',
				code: ''
			},
		}
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		}
	},
	created() {
		let mobile = getStorage('LOGIN_MOBILE')
		if(mobile){
			this.form.tel = mobile
		}else{
			this.form = {
				tel: '',
				code: ''
			}
		}
	},
	methods: {
		validationForm() {
			this.$refs.loginValidation.validate().then(success => {
				if (success) {
					this.doLogin()
				}
			})
		},
		doLogin () {
			let params = Object.assign({},this.form,{password: md5(this.form.password)})
			mobileLogin(params).then(res=>{
				let {avatar,nickname,token} = res
				let userInfo = {avatar,nickname,token}
				this.$store.commit("app/SET_USER_INFO",userInfo)
				if(this.isRemember){
					setStorage('LOGIN_MOBILE',this.form.tel)
				}else{
					removeStorage('LOGIN_MOBILE')
				}
				this.$router.replace('/admin')
			},err=>{
				this.$toast.error(err.message)
			})
		},
	},
}
</script>
