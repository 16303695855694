<template>
	<div class="auth-wrapper auth-v2">
		<b-row class="auth-inner m-0">
			<!-- Brand logo-->
			<b-link class="brand-logo" to="/">
				<img class="img" src="@/assets/images/logo/logo.png" alt="">
				<h2 class="brand-text text-primary ml-1">FruitScreen</h2>
			</b-link>
			<!-- /Brand logo-->

			<!-- Left Text-->
			<b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
				<div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
					<b-img fluid :src="imgUrl" alt="Login V2"/>
				</div>
			</b-col>
			<!-- /Left Text-->

			<!-- Login-->
			<b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
				<b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
					<b-card-title title-tag="h2" class="font-weight-bold mb-1">
						{{$t('Welcome to FruitScreen')}}! 👋
					</b-card-title>
					<b-card-text class="mb-2">{{$t('Please sign-in to your account and start the adventure')}}</b-card-text>
					
					<b-tabs content-class="pt-0" fill>
						<b-tab active :title="$t('Account Login')">
							<!-- 账号登录 -->
							<div class="divider mt-n1"></div>
							<account-login></account-login>
						</b-tab>
						<b-tab :title="$t('Captcha Login')">
							<!-- 验证码登录 -->
							<div class="divider mt-n1"></div>
							<mobile-login></mobile-login>
						</b-tab>
					</b-tabs>
					

					<b-card-text class="text-center mt-2">
						<span>{{$t('New on our platform')}}? </span>
						<b-link :to="{name:'register'}">
							<span>&nbsp;{{$t('Create an account')}}</span>
						</b-link>
					</b-card-text>

					<!-- divider -->
					<div class="divider my-2">
						<div class="divider-text"> or </div>
					</div>

					<!-- social buttons -->
					<div class="auth-footer-btn d-flex justify-content-center">
						<b-button variant="facebook" href="javascript:void(0)">
							<feather-icon icon="FacebookIcon" />
						</b-button>
						<b-button variant="twitter" href="javascript:void(0)">
							<feather-icon icon="TwitterIcon" />
						</b-button>
						<b-button variant="google" href="javascript:void(0)">
							<feather-icon icon="MailIcon" />
						</b-button>
						<b-button variant="github" href="javascript:void(0)">
							<feather-icon icon="GithubIcon" />
						</b-button>
					</div>
				</b-col>
			</b-col>
		<!-- /Login-->
		</b-row>
	</div>
</template>

<script>
/* eslint-disable global-require */
import AccountLogin from '@/components/login/AccountLogin.vue'
import MobileLogin from '@/components/login/MobileLogin.vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
	BRow, BCol, BLink,  BCardText, BCardTitle, BImg, BButton,BTabs,BTab
} from 'bootstrap-vue'

export default {
	components: {
		AccountLogin,
		MobileLogin,
		BRow,
		BCol,
		BLink,
		BCardText,
		BCardTitle,
		BImg,
		BButton,BTabs,BTab,
		VuexyLogo,
	},
	data() {
		return {
			sideImg: require('@/assets/images/pages/login-v2.svg'),
		}
	},
	computed: {
		imgUrl() {
			if (this.$store.state.appConfig.layout.skin === 'dark') {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
				return this.sideImg
			}
			return this.sideImg
		},
	},
	created() {
	},
	methods: {
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
